import React from 'react';
import './EventDescription.css';

const EventDescription = () => (
  <div className="event-desc-container">
    <h2 className="event-desc-invite">/////////// / You've been invited to: DANCE + + + + Do you accept? (Y/n):</h2>
    <h3 className="event-desc-lineup">{' PSYLOSUB • MELEE B2B • OPEN DECKS '}</h3>
    
    <div className="event-desc-details">
      <p><strong>Date:</strong> WED, October 30, 2024</p>
      <p><strong>Time:</strong> 9:30pm - 1:30am</p>
      <p><strong>Location:</strong> !** OPALINE **! [ 105 NW 3RD AVE. PDX ]</p>
      <p><strong>Age:</strong> 21+</p>
      <p>$10 ENTRY | $5  | $10 for added re-entry wristband</p>
    </div>
    
    <p className="event-desc-venue">Join us as we takeover WUB WEDNESDAYS Hosted by Wooklandia</p>
    
    <h3 className="event-desc-expect">DESCRIPTION:</h3>
    
    
    <p className="event-desc-reentry">Enter a night like no other—a haunting debut of two well tested music collectives joining forces at Opaline this Wednesday. Bring your friends and USBs, if you dare. Join us for open decks leading into a monumental Melee Music B2B followed by a bone shattering set by Psylosub 
    Then, as the clock creeps toward midnight, we invite you to reclaim the decks and rock the end of the night. The underground awaits…</p>
    
    <p className="event-desc-contact">
      If you have any questions, please do not hesitate to reach out to us at <a href="mailto:support@meleemusic.net">support@meleemusic.net</a> or on instagram @melee_music
    </p>
    
    <p className="event-desc-outro">We are excited to have you with us as we continue exploring sounds of the underground.</p>
    <p className="event-desc-outro">See you soon! 😈</p>

   
  </div>
);

export default EventDescription;